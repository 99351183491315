<template>
    <div class="max-w-md mx-auto p-4">
      <h1 class="text-3xl font-bold mb-6 text-center text-blue-600">Steam Convert</h1>
      <div class="bg-white shadow-md rounded-lg p-6">
        <input
          v-model="query"
          @keyup.enter="searchSteam"
          placeholder="Enter Steam Profile URL"
          class="w-full px-4 py-2 border border-gray-300 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
        <button
          @click="searchSteam"
          class="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
        >
            Convert
        </button>
        <div v-if="loading" class="mt-4 text-gray-500 flex items-center justify-center">
          <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
          </svg>
          Loading...
        </div>
        <div v-if="error" class="mt-4 text-red-500">{{ error }}</div>
        <div v-if="result" class="mt-4 bg-gray-100 p-4 rounded">
          <h2 class="text-xl font-semibold mb-2">Result:</h2>
          <pre class="whitespace-pre-wrap">{{ result }}</pre>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        query: '',
        result: null,
        error: null,
        loading: false
      };
    },
    methods: {
      async searchSteam() {
        this.loading = true;
        this.error = null;
        this.result = null;
        try {
          const response = await axios.post('https://steam-convert-api.aona.town/get-steam-hex', {
            url: this.query
          });
          this.result = response.data.steamHex;
        } catch (err) {
          this.error = err.message || 'An error occurred';
        } finally {
          this.loading = false;
        }
      }
    }
  };
  </script>
  
  