<template>
  <div id="app" class="bg-gray-50 min-h-screen flex items-center justify-center">
    <SearchSteam />
  </div>
</template>

<script>
import SearchSteam from './components/SearchSteam.vue';

export default {
  components: {
    SearchSteam
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
